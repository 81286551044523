import axios from 'axios'
import { mount } from '@core/mount'

interface IAllergen {
  name: string
  slug: string
  diary: boolean
  eggs: boolean
  fish: boolean
  gluten: boolean
  peanuts: boolean
  shellfish: boolean
  soy: boolean
  treenuts: boolean
  wheat: boolean
}

const toggleDot = (element: JQuery<Element>, allergen: string, show = true) => {
  const dot = element.find(`[data-allergen="${allergen}"]`)
  show ? dot.show() : dot.hide()
}

const setAllergens = async () => {
  const { data: menuItems } = await axios.get(process.env.API_URL + '/allergens')
  const template = $('[data-allergen-template]')[0]
  const ingredients = ['dairy', 'diary', 'eggs', 'fish', 'shellfish', 'treenuts', 'peanuts', 'wheat', 'soy', 'gluten']

  menuItems.forEach((item: any) => {
    const { allergens } = item

    Object.keys(allergens).forEach((slug) => {
      const values: IAllergen = $(allergens[slug])[0]
      const element = $(template).clone()
      const target = $(`[data-menu-item="${item.slug}"]`).parents('.accordion_content').find('.allergens_wrap')

      // Set the values for each item
      element.find('[data-allergen-title]').text(values.name)
      // @ts-expect-error
      ingredients.forEach((ingredient) => toggleDot(element, ingredient, values[ingredient]))

      // Display it on the page
      target.append(element)
      element.css({ display: 'grid', gridGap: 0 })
    })
  })
}

const Nutritionals = (root: HTMLElement) => {
  return {
    start: mount(root, setAllergens),
  }
}

export default Nutritionals
